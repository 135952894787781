import React from "react";
import { Spinner } from "react-bootstrap";

const Loader = () => (
  <div className="d-flex flex-column align-items-center justify-content-center my-3">
    <h3 className="px-2 mb-3 font-weight-bold"> Loading... </h3>
    <Spinner animation="border" variant="secondary" />
  </div>
);

export default Loader;
